import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {getAuth, isSignInWithEmailLink, signInWithEmailAndPassword, signInWithEmailLink, fetchSignInMethodsForEmail} from "firebase/auth";
import MooslixLogoBlack from "../../assets/images/MooslixLogoBlack.svg";
import MooslixLogoTextBlack from "../../assets/images/MooslixLogoTextBlack.svg";
import SignUpHand from "../../assets/images/SignUpHand.png";
import {uploadProfilePhoto} from "../../services/storageService";
import {useAuth} from "../../context/AuthContext";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Parse query parameters from URL
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    const orgId = searchParams.get("orgId");
    const status = searchParams.get("status");

    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const savedEmail = window.localStorage.getItem("emailForSignIn") || emailParam || window.prompt("Please confirm your email:");
      if (!savedEmail) {
        alert("Email is required to complete the verification process.");
        return;
      }
      signInWithEmailLink(auth, savedEmail, window.location.href)
        .then((result) => {
          console.log("Email verified:", result.user);
          navigate(`/register/onboard?email=${savedEmail}&orgId=${orgId}&status=${status}`);
        })
        .catch((error) => {
          console.error("Error during sign-in:", error);
          alert("Failed to complete email verification.");
        });
    }

  }, [location]);

  return (
    <main className="w-screen h-screen flex justify-center items-center bg-gray-100">
      <section className="w-full h-full flex shadow-lg rounded-lg overflow-hidden bg-white">
        <div className="w-full md:w-3/5 flex flex-col p-8">
          <div className="flex items-center mb-8">
            <a className="flex items-center" href="/">
              <img className="h-10" src={MooslixLogoBlack} alt="Logo" />
              <img className="h-10 ml-2" src={MooslixLogoTextBlack} alt="Mooslix" />
            </a>
          </div>
          <div className="flex flex-col items-center justify-center flex-grow">
            Verifying...
          </div>
        </div>
        <div className="hidden md:block md:w-2/5 relative bg-primary text-white p-8 flex flex-col justify-center items-center">
          <img
            className="absolute -bottom-20 inset-x-0 w-full h-full object-cover opacity-30"
            src={SignUpHand}
            alt="Sign Up"
          />
          <div className="relative text-center z-10">
            <h2 className="text-4xl font-medium mb-4">Secure Your Future</h2>
            <p className="text-lg">with Advanced Biometrics</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Register;