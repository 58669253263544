import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {arrayRemove, doc, getDoc, updateDoc} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebaseConfig'; // Adjust to your Firebase config path
import ChevronRight from '../../assets/icons/RightChevronBlack.svg';
import DragAndDropPreview from "../specific/RecipientFlows"; // Adjust to the actual path
import NewWindowIcon from "../../assets/icons/NewWindowIcon.svg"
import DataSendTable from "./DataSendTable";
import {useOrganization} from "../../context/OrgContext";
import exportService, {testSendDataHTTP} from "../../services/exportService";
import {BiDownload} from "react-icons/bi";
import {ImLoop2} from "react-icons/im";
import Dropdown from "../common/Dropdown";
import GroupIcon from "../../assets/icons/GroupIcon.svg";
import SortIcon from "../../assets/icons/SortBlack.svg";
import FilterIcon from "../../assets/icons/ConfigureIconBlack.svg";
import {BiMinus} from "react-icons/bi";
import RenderRemove from "../specific/RenderRemove";


const RecipientInterface = () => {
    const { id: recipientId } = useParams(); // Extract recipientId from the URL
    const [loading, setLoading] = useState(true); // Track loading state
    const [recipient, setRecipient] = useState(null); // Store the selected recipient
    const [logoUrl, setLogoUrl] = useState(''); // Store the recipient's logo URL
    const navigate = useNavigate();
    const [selectedDataType, setSelectedDataType] = useState("")
    const {focusedOrg} = useOrganization();
    const [removeMenuOpen, setRemoveMenuOpen] = useState(false);
    const [recipientToRemove, setRecipientToRemove] = useState(null);


    useEffect(() => {
        const fetchRecipient = async () => {
            try {
                if (!recipientId) {
                    console.warn('No recipient ID found in the URL.');
                    setLoading(false);
                    return;
                }

                // Fetch recipient document from Firestore
                const recipientRef = doc(db, 'recipients', recipientId);
                const recipientDoc = await getDoc(recipientRef);

                if (recipientDoc.exists()) {
                    const recipientData = { id: recipientDoc.id, ...recipientDoc.data() };
                    setRecipient(recipientData);
                    setSelectedDataType(recipientData.DataTypes[0])

                    // Fetch logo URL from Firebase Storage
                    if (recipientData.Logo) {
                        const storage = getStorage();
                        const logoRef = ref(storage, `recipient_logos/${recipientData.Logo}`);
                        const logoDownloadUrl = await getDownloadURL(logoRef);
                        setLogoUrl(logoDownloadUrl);
                    }
                } else {
                    console.error('Recipient not found!');
                }
            } catch (error) {
                console.error('Error fetching recipient:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecipient();
    }, [recipientId]);

    const handleOpenRemoveMenu = (recipientName) => {
        setRecipientToRemove(recipientName);
        setRemoveMenuOpen(true);
      };

    const handleExport = (DataTypes) => {
        console.log(DataTypes)
        exportService.exportData(focusedOrg, DataTypes);
        testSendDataHTTP()
          .then((response) => {
            console.log("Function executed successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error executing function:", error);
          });
    };

    const handleRecipientRemove = async (orgId, recipientId) => {
  try {
    if (!orgId || !recipientId) {
      console.error("Organization ID or recipient ID is missing.");
      return;
    }

    // Reference to the organization document
    const orgDocRef = doc(db, "organization_data", orgId);

    // Remove the recipient from the organization's Recipients array
    await updateDoc(orgDocRef, {
      Recipients: arrayRemove(recipientId),
    });

    console.log(`Recipient ${recipientId} removed from organization ${orgId}.`);

    // Reference to the recipient document
    const recDocRef = doc(db, "recipients", recipientId);

    // Remove the organization from the recipient's Providers array
    await updateDoc(recDocRef, {
      Providers: arrayRemove(orgId),
    });

    console.log(`Organization ${orgId} removed from recipient ${recipientId}.`);

    navigate(`/app/recipients/new`);
  } catch (error) {
    console.error("Error removing recipient from organization:", error);
  }
};

    const toggleRemoveMenu = () => {
        setRemoveMenuOpen(!removeMenuOpen)
    }

    const renderRecipientStatus = (status) => {

        return (
        <>
            {status ? (
                <div className="flex-grow rounded-lg transition mb-1 flex items-center justify-between space-x-4">
                    <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                        <div className="h-2/3 opacity-80 w-0.5 bg-green-600 rounded-full"></div>
                        <div className="text-xl text-green-600 font-semibold">Connected</div>
                    </div>
                    <div className="relative flex justify-center items-center h-fit">
                        <div className="absolute animate-ping bg-green-600 opacity-50 w-2.5 h-2.5 rounded-full"></div>
                        <div className="absolute bg-green-600 w-2.5 h-2.5 opacity-80 rounded-full"></div>
                    </div>
                </div>
            ) : (
                <div className="flex-grow rounded-lg transition mb-1 flex items-center justify-between space-x-4">
                    <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                        <div className="h-2/3 opacity-80 w-0.5 bg-red-600 rounded-full"></div>
                        <div className="text-xl text-red-600 font-semibold">Disconnected</div>
                    </div>
                    <div className="relative flex justify-center items-center h-fit">
                        <div className="absolute bg-red-600 w-2.5 h-2.5 opacity-80 rounded-full"></div>
                    </div>
                </div>
            )}
        </>
    )};

    return (
        <div className="px-6 py-2 bg-slate-50 min-h-screen flex flex-col">
            {removeMenuOpen && <RenderRemove
          closeMenu={() => setRemoveMenuOpen(false)}
          recipientName={recipientToRemove}
          onConfirm={() => handleRecipientRemove(focusedOrg, recipient.id)}
        />}
            <div className="flex justify-between space-x-4 h-fit">
                <div className="flex-grow rounded-lg border h-fit p-2 bg-white flex">
                    <div className="flex items-center space-x-0.5">
                        <div className="py-0.5 px-1 flex transition rounded items-center cursor-default">
                            <h1 className="text-sm font-semibold text-slate-500 font-Poppins">Recipients</h1>
                        </div>
                        <img src={ChevronRight} className="h-2 w-2 opacity-30"/>
                        <div
                            className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center"
                            onClick={() => navigate(-1)}
                        >
                            <a href={`${recipient?.WebsiteLink || ""}`}
                               target="_blank" // Open in a new tab
                               rel="noopener noreferrer" // Security measure
                               className="text-sm font-semibold text-slate-700 font-Poppins flex items-center space-x-1"
                            >
                                <div>{recipient ? recipient.Name || "Unnamed Recipient" : "Loading..."}</div>
                                <img src={NewWindowIcon} className={"h-3.5 w-3.5"}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={"space-x-4 flex w-fit"}>
                    <div className={"h-full rounded-lg flex items-center w-fit text-sm bg-slate-700 hover:opacity-80 transition cursor-pointer p-2 font-semibold text-white"}
                        onClick={() => handleOpenRemoveMenu(`${recipient.Name || 'Recipient'}`)}>
                        <BiMinus style={{stroke: "white"}} className={"h-5 w-5 mr-1"}/>
                        Remove Recipient
                    </div>
                </div>
            </div>
            <div className="font-Poppins lg:flex-grow h-fit w-full rounded-lg border overflow-clip bg-white flex flex-col mt-4">
                {loading ? (
                    <p>Loading recipient...</p>
                ) : recipient ? (
                    <div className={"flex flex-col w-full h-full"}>
                        <div className={"h-16 border-b bg-slate-50 flex items-center justify-between"}>
                            <div className={"h-full flex items-center"}>
                                <div className="h-full p-2">
                                    {logoUrl ? (
                                        <img src={logoUrl} alt={`${recipient.Name || 'Recipient'} Logo`}
                                             className="h-full aspect-square rounded-xl"/>
                                    ) : (
                                        <p>No logo available</p>
                                    )}
                                </div>
                                <h2 className="font-semibold text-xl">{recipient.Name || 'Unnamed Recipient'}</h2>
                            </div>
                            <div className={"mr-6"}>
                                {renderRecipientStatus(recipient.Status)}
                            </div>
                        </div>
                        <div className={"flex-grow h-full flex flex-col"}>
                            <DragAndDropPreview recipientId={recipient.id} selectedType={selectedDataType}/>
                            <div
                                className={"w-full h-fit bg-white border-t flex px-6 py-2.5 items-center justify-between"}>
                                <div className={"flex items-center space-x-2 "}>
                                    <div className={"text-sm font-medium opacity-50"}>Data Send:</div>
                                    <div
                                        className={"bg-slate-100 flex items-center group text-slate-500 py-1 pl-2 pr-1 rounded-lg text-sm font-semibold hover:text-slate-800 hover:bg-slate-200 transition cursor-pointer"}>
                                        Every Week
                                        <div
                                            className={"flex bg-slate-200 group-hover:bg-slate-300 group-hover:text-slate-800 transition ml-2 rounded items-center justify-center px-1 font-medium"}>
                                            Friday
                                            <ImLoop2 color={"#64748b"} className={"h-3 w-3 ml-2"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex items-center space-x-2 group rounded-lg py-1 pl-1 pr-3 cursor-pointer bg-slate-100 hover:bg-slate-200 transition"}
                                    //onClick={() => handleExport(recipient.DataTypes)}
                                >
                                    <div className={"text-sm font-semibold text text-slate-500 py-0.5 pl-2"}>Download All Sent Data
                                    </div>
                                    <BiDownload color={"#64748b"} className={"h-5 w-5"}/>
                                </div>
                            </div>
                            <div className={"w-full flex-grow overflow-clip"}>
                                <DataSendTable orgId={focusedOrg} recipientId={recipientId} recipientName={recipient.Name}/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>No recipient found.</p>
                )}
            </div>
        </div>
    );
};

export default RecipientInterface;
