import React from "react";
import Plus from "../../assets/icons/PlusBlack.svg";
import bglines from "../../assets/images/bg-line1.svg"
import bglines2 from "../../assets/images/bg-line2.svg"
import bglines3 from "../../assets/images/bg-line3.svg"

const boxes = [
  {
      title: "Dynamic Control",
      image: bglines
  },
  {
      title: "Key Visualizations",
      image: bglines2
  },
  {
      title: "No-Code Plug-Ins",
      image: bglines3
  },
];

export default function Boxes() {
  return (
      <div className="h-fit w-full flex flex-col mb-10 relative">
          <div className="w-full h-fit flex flex-col lg:flex-row px-4">
              <h1 className="lg:w-1/2 w-full text-5xl font-medium text-center lg:text-start font-Poppins">
                  Made for today's trailblazing teams
              </h1>
              <h2 className="lg:w-1/2 font-Poppins w-full text-base text-slate-300 lg:pl-16 lg:pr-8 text-center lg:text-start">
                  Mooslix elevates your team's authentication with cardless access control and payment processing,
                  streamlined by one comprehensive web platform.
              </h2>
          </div>
          <div className="px-4 flex flex-col lg:flex-row w-full space-y-4 lg:space-y-0 lg:space-x-4 mt-16">
              {boxes.map((box, index) => (
                  <div
                      key={index}
                      className="relative overflow-clip lg:w-80 w-full h-32 lg:h-auto lg:aspect-square bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl md:rounded-lg shadow-lg border border-white border-opacity-40 flex items-end justify-between text-xl text-black"
                  >
                      <img src={box.image} className={"absolute w-full h-full object-cover opacity-50"}/>
                      <div className="mb-4 ml-4 font-medium font-Poppins">{box.title}</div>
                      <div
                          className="group h-9 w-9 mb-4 mr-4 rounded-full p-2 transition cursor-pointer bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg hover:shadow-lg shadow-md border border-slate-200 border-opacity-40">
                          <img className="group-hover:opacity-75 opacity-50 transition" src={Plus}/>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
}
