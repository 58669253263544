import React, {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRight from "../../assets/icons/RightChevronBlack.svg";
import PlusIcon from "../../assets/icons/PlusBlack.svg";
import EyeIcon from "../../assets/icons/DetailsBlack.svg"
import {fetchEntriesForItem, fetchTransactions} from "../../services/firestoreService"
import {useOrganization} from "../../context/OrgContext";
import highBatteryIcon from "../../assets/icons/HighBattery.svg"
import mediumBatteryIcon from "../../assets/icons/MedBattery.svg"
import lowBatteryIcon from "../../assets/icons/LowBattery.svg"
import {FcEmptyBattery} from "react-icons/fc";
import {TbForbid} from "react-icons/tb";
import LogComponent from "./LogComponent";

const RegisterDetailsPage = () => {
    const {focusedOrg} = useOrganization();
    const location = useLocation();
    const navigate = useNavigate();
    const { item } = location.state || {};
    const [trafficLevel, setTrafficLevel] = useState(null); // Store traffic level
    const [loading, setLoading] = useState(true); // Track loading state
    const [popData, setPopData] = useState(null);

    useEffect(() => {
      const analyzeTrafficLevel = async () => {
        try {
          // Fetch the entries for the lock
          const transactions = await fetchTransactions(focusedOrg, item.Site, item.id);
          setPopData(transactions)

          // Calculate traffic level
          const level = getTrafficLevelText(transactions);
          setTrafficLevel(level); // Update state with the computed traffic level
        } catch (error) {
          console.error("Error fetching or analyzing traffic level:", error);
          setTrafficLevel("Unknown");
        } finally {
          setLoading(false); // Stop loading once processing is complete
        }
      };

      analyzeTrafficLevel();
    }, [focusedOrg, item]); // Refetch if `focusedOrg` or `item` changes

    if (!item) {
        return <div>No details available</div>;
    }

    // Helper to calculate traffic level
    const getTrafficLevelText = (transactions) => {
        const count = transactions.length;

        if (count > 50) {
          return "High";
        } else if (count > 20) {
          return "Moderate";
        } else if (count > 0) {
          return "Low";
        } else {
          return "None";
        }
    };



    // Helper for traffic level text color
    const getTrafficLevelTextColor = () => {
      switch (trafficLevel) {
          case "High":
            return "text-red-600";
          case "Moderate":
            return "text-yellow-600";
          case "Low":
            return "text-green-600";
          case "None":
            return "text-gray-600";
          default:
            return "text-gray-400";
      }
    };

    const getTrafficLevelBGColor = () => {
      switch (trafficLevel) {
          case "High":
            return "bg-red-600";
          case "Moderate":
            return "bg-yellow-600";
          case "Low":
            return "bg-green-600";
          case "None":
            return "bg-gray-600";
          default:
            return "bg-gray-400";
      }
    };

    const getTrafficLevelIcon = () => {
      switch (trafficLevel) {
          case "High":
            return <img src={ChevronRight} className={"h-4 w-4 -rotate-90"}/>;
          case "Moderate":
              return <div className={"h-1 w-5 rounded-full bg-black"}/>;
          case "Low":
              return <img src={ChevronRight} className={"h-4 w-4 rotate-90"}/>;
          case "None":
              return <img src={PlusIcon} className={"h-5 w-5 scale-150 rotate-45"}/>;
          default:
              return <img src={PlusIcon} className={"h-5 w-5 scale-150 rotate-45"}/>;
      }
    };

    const getBatteryLevelIcon = (batteryLevel) => {
      if (batteryLevel > 75) {
        return <img src={highBatteryIcon} className={"h-5 w-5 scale-150"} />;
      } else if (batteryLevel > 25) {
        return <img src={mediumBatteryIcon} className={"h-5 w-5 scale-150"} />;
      } else if (batteryLevel > 0) {
        return <img src={lowBatteryIcon} className={"h-5 w-5 scale-150"} />;
      } else if (batteryLevel === 0) {
        return <img src={PlusIcon} className={"h-5 w-5 scale-150"} />;
      } else {
        return <FcEmptyBattery/>;
      }
    };



    return (
        <div className="px-6 py-2 bg-slate-50 min-h-screen flex flex-col font-Poppins">
            <div className={"w-full rounded-lg border h-fit p-2 bg-white flex"}>
                <div className="flex items-center space-x-0.5">
                    <div className="py-0.5 px-1 flex transition rounded items-center cursor-default">
                        <h1 className="text-sm font-semibold text-slate-500">Devices</h1>
                    </div>
                    <img src={ChevronRight} className={"h-2 w-2 opacity-30"}/>
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center"
                         onClick={() => navigate(-1)}>
                        <h1 className="text-sm font-semibold text-slate-700">Registers</h1>
                    </div>
                    <img src={ChevronRight} className={"h-2 w-2 opacity-30"}/>
                    <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                        <h1 className="text-sm font-semibold text-slate-700">{item.Name}</h1>
                    </div>
                </div>
            </div>
            <div className={"w-full h-fit rounded-lg border p-4 bg-white flex flex-col mt-4"}>
                <div className={"flex w-full justify-between pb-2"}>
                    <div className={"flex space-x-4"}>
                        <div className={"text-2xl font-semibold"}>{item.Name}</div>
                        <div
                            className={"border rounded-full text-base font-regular text-slate-400 border-slate-300 px-2 content-center"}>{item.siteName}</div>
                    </div>
                    <div
                        className={"border border-slate-200 bg-slate-50 hover:bg-slate-100 rounded w-32 flex items-center justify-center space-x-1 group cursor-pointer transition"}>
                        <img className={"h-5 w-5 opacity-50 group-hover:opacity-80 transition"} src={EyeIcon}/>
                        <div className={"opacity-50 group-hover:opacity-80 font-medium text-sm transition"}>Show
                            Details
                        </div>
                    </div>
                </div>

                <div className={"flex-row space-x-4 flex"}>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Income Today</div>
                            <div className={"text-slate-400 font-regular"}></div>
                        </div>
                    </div>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Battery Level</div>
                            <div className={"text-slate-400 font-regular"}></div>
                        </div>
                        <div
                            className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                            <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                                <div className={`h-2/3 opacity-80 bg-black w-0.5 rounded-full`}></div>
                                <div className={`text-3xl opacity-80 font-semibold`}>{item.BatteryLevel}%</div>
                            </div>
                            <div className="flex justify-center items-center h-fit scale-125 opacity-80">
                                {getBatteryLevelIcon(item.BatteryLevel)}
                            </div>
                        </div>
                        <div className={"h-4 w-full"}>
                            <div className={"text-sm font-medium text-slate-400"}>35 Members Have Access</div>
                        </div>
                    </div>
                    <div className={"h-28 w-1/3 border rounded-lg p-2 flex flex-col"}>
                        <div className={"flex w-full justify-between"}>
                            <div className={"text-slate-400 font-medium"}>Transaction Traffic</div>
                            <div className={"text-slate-400 font-regular"}></div>
                        </div>
                        <div
                            className="flex-grow hover:bg-slate-50 cursor-pointer rounded-lg transition mb-1 flex items-center justify-between space-x-3 pr-3">
                            <div className="flex items-center justify-center h-full overflow-clip space-x-2">
                                <div
                                    className={`h-2/3 ${getTrafficLevelBGColor()} opacity-80 w-0.5 rounded-full`}></div>
                                <div
                                    className={`text-3xl ${getTrafficLevelTextColor()} font-semibold`}>{trafficLevel}</div>
                            </div>
                            <div className="flex justify-center items-center h-fit scale-125 opacity-65">
                                {getTrafficLevelIcon()}
                            </div>
                        </div>
                        <div className={"h-4 w-full flex justify-between"}>
                            <div className={"text-sm font-medium text-slate-400"}>Average: 45 Scans / Hour</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"h-fit w-full flex flex-col mt-6"}>
                {popData ? <LogComponent pops={popData} type={"Registers"} Name={item.Name}/> : <div className={""}>Loading</div> }
            </div>
        </div>
    );
};

export default RegisterDetailsPage;
