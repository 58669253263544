import React, { useState } from 'react';
import StandImage from "../../assets/images/Stand.png"
import ReaderImage from "../../assets/images/Reader.png"
import QRSupport from "../../assets/icons/QRSupport.svg"
import PlatformSupport from "../../assets/icons/PlatformSupport.svg"
import USBC from "../../assets/icons/USBC.svg"
import RighChevronBlack from "../../assets/icons/RightChevronBlack.svg"

const hardwareItems = [
    { id: 1, name: "Lock", icon: ReaderImage, details: "Mooslix Lock  can cover a variety of authentication tasks from door security to check-ins.", chargingMode: "USB Type-C Wired Connection", Platform: "Native Platform Support and Insights", Modality: "Custom QR Support" },
    { id: 2, name: "Register", icon: StandImage, details: "Mooslix Register offers touchless payment via palm scanning or touch-to-pay to streamline sales into one powerful system.", chargingMode: "USB Type-C Wired Connection", Platform: "Native Platform Support and Insights", Modality: "Touchless Card Compatability" },
];

export default function HardwareShop() {
    const [selectedItem, setSelectedItem] = useState(hardwareItems[0]);

    return (
        <div className="w-full mt-20 md:border-t flex flex-col">
            {/* Hardware items menu */}
            <div className="flex justify-center items-center py-4 bg-white shadow space-x-8 px-16 xl:px-64 border-b md:border-0">
                {hardwareItems.map(item => (
                    <div
                        key={item.id}
                        className="flex flex-col items-center p-1 h-24 aspect-square border rounded-lg hover:bg-slate-50 cursor-pointer transition hover:shadow-lg"
                        onClick={() => setSelectedItem(item)}
                    >
                        <div className={"flex flex-col items-center p-3 h-full w-full bg-white rounded-md"}>
                            <img src={item.icon} alt={item.name} className="h-10 w-10"/>
                            <span className="mt-2 text-sm font-Poppins font-medium opacity-75">{item.name}</span>
                        </div>
                    </div>
                ))}
            </div>

            {/* Main content */}
            <div className="flex flex-col p-4 xl:p-10 bg-white text-black h-fit md:h-screen w-screen md:border-t">
                <div className={"flex md:flex-row flex-col-reverse w-full h-full md:px-32"}>
                    <div className={"flex flex-col space-y-2 w-full md:w-1/2 lg:p-16"}>
                        <h1 className="text-8xl font-bold pt-8 pb-6 font-Poppins">{selectedItem.name}</h1>
                        <p className="mb-8 font-Poppins bg-slate-50 rounded-2xl flex-grow p-4 text-sm font-medium">
                            {selectedItem.details}
                        </p>
                        <div className={"h-fit space-y-2 w-full flex flex-col justify-between py-4"}>
                            <div
                                className={"bg-slate-50 rounded-full h-14 py-2 px-2 w-full flex items-center space-x-2"}>
                                <div className={"h-full aspect-square rounded-full bg-slate-200 p-2 flex items-center justify-center"}>
                                    <img src={USBC} className={"w-full h-full"}/>
                                </div>
                                <h1>{selectedItem.chargingMode}</h1>
                            </div>
                            <div
                                className={"bg-slate-50 rounded-full h-14 py-2 px-2 w-full flex items-center space-x-2"}>
                                <div
                                    className={"h-full aspect-square rounded-full bg-slate-200 p-2 flex items-center justify-center"}>
                                    <img src={PlatformSupport} className={"w-full h-full"}/>
                                </div>
                                <h1>{selectedItem.Platform}</h1>
                            </div>
                            <div
                                className={"bg-slate-50 rounded-full h-14 py-2 px-2 w-full flex items-center space-x-2"}>
                                <div
                                    className={"h-full aspect-square rounded-full bg-slate-200 p-2 flex items-center justify-center"}>
                                    <img src={QRSupport} className={"w-full h-full"}/>
                                </div>
                                <h1>{selectedItem.Modality}</h1>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <button className="bg-black text-white py-2 px-4 rounded-lg">Join Waitlist</button>
                            <button className="bg-slate-100 text-black py-2 px-4 rounded-lg flex items-center space-x-2">
                                <h1>Learn more</h1>
                                <img src={RighChevronBlack} className={"h-2.5 w-2.5"}/>
                            </button>
                        </div>
                    </div>
                    <div className={"w-full md:w-1/2 lg:p-16 rounded-2xl h-full overflow-clip object-contain items-center flex"}>
                        <img src={selectedItem.icon} className={"bg-white rounded-lg object-contain md:w-full md:aspect-square w-full h-full"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
