import React, { useEffect, useState } from "react";
import {
  ReactFlow,
  Background,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";

import EditIcon from "../../assets/icons/EditIcon.svg";
import "@xyflow/react/dist/style.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import NavToolTip from "../layout/NavToolTip";

const AnimatedEdge = ({ id, sourceX, sourceY, targetX, targetY, style }) => {
  return (
    <g>
      <path
        id={id}
        className="react-flow__edge-path"
        d={`M ${sourceX},${sourceY} C ${(sourceX + targetX) / 2},${sourceY} ${
          (sourceX + targetX) / 2
        },${targetY} ${targetX},${targetY}`}
        style={{
          ...style,
          strokeDasharray: "5,5",
          animation: "dashAnimation 1s linear infinite",
        }}
      />
      <style>
        {`
          @keyframes dashAnimation {
            to {
              stroke-dashoffset: -10;
            }
          }
        `}
      </style>
    </g>
  );
};

export default function RecipientFlow({ recipientId, selectedType }) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [loading, setLoading] = useState(true);
  const [recipientData, setRecipientData] = useState([]);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(window.innerWidth);
      setContainerHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchRecipientData = async () => {
      if (!recipientId) {
        console.error("Recipient ID is required");
        setLoading(false);
        return;
      }

      try {
        const recipientRef = doc(db, "recipients", recipientId);
        const recipientDoc = await getDoc(recipientRef);

        if (recipientDoc.exists()) {
          const recipientData = recipientDoc.data();
          const dataTypes = recipientData.DataTypes || [];
          setRecipientData(dataTypes);
          const recipientName = recipientData.Name || "Unnamed Recipient";

          const initialNodes = [
            ...dataTypes.map((type, index) => ({
              id: `dataType-${index}`,
              data: { label: type + " Data" },
              position: { x: 0, y: index * 50 },
              style: {
                background: `${selectedType === type ? "#f8fafc" : "#f8fafc"}`,
                color: `${selectedType === type ? "#000" : "#000"}`,
                border: `0.5px solid ${selectedType === type ? "#e2e8f0" : "#e2e8f0"}`,
                borderRadius: "8px",
                padding: "10px",
              },
              sourcePosition: "right",
            })),
            {
              id: "recipient",
              data: { label: recipientName },
              position: { x: containerWidth / 2 - 200, y: (dataTypes.length - 1) * 25 },
              style: {
                background: "#175B32",
                color: "#fff",
                border: "0.5px solid #e2e8f0",
                borderRadius: "8px",
                padding: "10px",
              },
              targetPosition: "left",
            },
          ];

          const initialEdges = dataTypes.map((_, index) => ({
            id: `edge-${index}`,
            source: `dataType-${index}`,
            target: "recipient",
            type: "customAnimatedEdge",
          }));

          setNodes(initialNodes);
          setEdges(initialEdges);


        } else {
          console.error("Recipient not found in Firebase.");
        }
      } catch (error) {
        console.error("Error fetching recipient data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecipientData();
  }, [recipientId, containerWidth, containerHeight]);

  if (loading) {
    return <div>Loading flow...</div>;
  }

  return (
    <div
      className="relative"
      style={{ height: 150, width: "100%" }}
    >
      <div
        className="bg-slate-100 h-fit w-fit absolute left-4 top-4 shadow border p-1 rounded-lg flex items-center space-x-2 z-10"
      >
        <NavToolTip dataTypes={recipientData} focusedType={selectedType} />
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={false}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        edgeTypes={{
          customAnimatedEdge: AnimatedEdge,
        }}
      >
        <Background variant="dots" gap={16} size={1} />
      </ReactFlow>
    </div>
  );
}
