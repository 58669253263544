import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig'; // Adjust the path as necessary
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import BillingHistoryTable from "../layout/BillingHistoryTable";
import StructureDiagram from "../layout/Structure";
import {useOrganization} from "../../context/OrgContext";
import {fetchSites} from "../../services/firestoreService";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import SitesList from "./SitesList";


const OrganizationSettings = () => {
    const {focusedOrg} = useOrganization()
    const [activeTab, setActiveTab] = useState('Appearance');
    const [orgData, setOrgData] = useState({
        Name: '',
        Logo: '',
        Structure: '',
        BillingInfo: '',
    });
    const [sites, setSites] = useState([])
    const [loading, setLoading] = useState(true);
    const [logoFile, setLogoFile] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [newSite, setNewSite] = useState({ Name: '', Address: '' });
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const fetchOrgData = async () => {
            if (focusedOrg) {
                try {
                    const orgDocRef = doc(db, 'organization_data', focusedOrg);
                    const orgDoc = await getDoc(orgDocRef);

                    if (orgDoc.exists()) {
                        const data = orgDoc.data();
                        setOrgData(data);

                        // Fetch the logo URL from storage
                        if (data.Logo) {
                            try {
                                const logoRef = ref(storage, data.Logo);
                                const url = await getDownloadURL(logoRef);
                                setLogoUrl(url);
                            } catch (error) {
                                console.error('Error fetching logo URL:', error);
                            }
                        }
                    }

                    // Fetch sites data
                    const siteData = await fetchSites(focusedOrg); // Await the promise here
                    setSites(siteData); // Set the resolved data to `sites`
                } catch (error) {
                    console.error('Error fetching organization data:', error);
                }
            }
            setLoading(false);
        };

        fetchOrgData();
    }, [focusedOrg]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOrgData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleLogoChange = (e) => {
        if (e.target.files[0]) {
            setLogoFile(e.target.files[0]);
        }
    };

    const handleSave = async () => {
        try {
            const orgDocRef = doc(db, 'organization_data', focusedOrg);
            await updateDoc(orgDocRef, {
                Name: orgData.Name,
                Structure: orgData.Structure,
                BillingInfo: orgData.BillingInfo,
            });

            if (logoFile) {
                const logoRef = ref(storage, `org_logos/${focusedOrg}`);
                const uploadTask = uploadBytesResumable(logoRef, logoFile);

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        // You can add progress tracking here if desired
                    },
                    error => {
                        console.error('Error uploading logo:', error);
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        await updateDoc(orgDocRef, { Logo: downloadURL });
                        setLogoUrl(downloadURL);
                    }
                );
            }

            alert('Settings saved successfully');
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('Failed to save settings');
        }
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Appearance':
                return (
                    <div className="space-y-4 flex flex-col">
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Organization Name</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Change the name of your
                                    organization</h1>
                            </div>
                            <div className={"flex flex-col flex-grow"}>
                                <input
                                    type="text"
                                    name="Name"
                                    value={orgData.Name}
                                    onChange={handleInputChange}
                                    className="h-full p-2 border border-gray-300 rounded w-full"
                                />
                            </div>
                        </div>
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Organization Logo</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Update your organization logo</h1>
                            </div>
                            <div className={"flex flex-grow space-x-4"}>
                                {logoUrl ? (
                                    <img
                                        src={logoUrl}
                                        alt="Org Logo"
                                        className="w-24 h-24 rounded-lg"
                                    />
                                ) : (
                                    <div className="w-24 h-24 rounded-lg bg-slate-100"></div>
                                )}
                                <div className="flex-grow h-24 rounded-lg border-slate-100 border-2 border-dashed flex items-center justify-center">
                                    <input
                                        type="file"
                                        name="Logo"
                                        onChange={handleLogoChange}
                                        className=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full h-fit py-4 border-b space-x-4">
                            <div className={"flex flex-col space-y-0.5 w-2/5"}>
                                <h1 className={"font-semibold text-sm"}>Brand Color</h1>
                                <h1 className={"text-sm font-medium opacity-40"}>Choose your organization's highlight color</h1>
                            </div>
                            <div className={"flex flex-grow space-x-4"}>

                            </div>
                        </div>
                    </div>
                );
            case 'Sites':
                return (
                    <SitesList focusedOrg={focusedOrg} />
                );
            case 'Plans & Billing':
                return (
                    <div className="mt-4 flex-grow w-full space-y-4 flex flex-col">
                        <div className={"flex flex-grow w-full flex-col"}>
                            <div className={"w-full flex flex-row flex-grow space-x-4"}>
                                <div
                                    className={"h-full w-full flex flex-col justify-between bg-white rounded-lg shadow-lg p-4"}>
                                    <div className={"text-slate-300 font-medium text-sm"}>Due April 1</div>
                                    <div className={"text-4xl text-black font-semibold"}>$0.00</div>
                                </div>
                                <div
                                    className={"h-full w-full flex flex-row justify-between bg-white rounded-lg shadow-lg p-4"}>
                                    <div className={""}></div>
                                    <div className={""}></div>
                                </div>
                            </div>
                            <div className={"bg-slate-200 rounded-lg w-full h-0.5 mt-4 opacity-75"}></div>
                        </div>
                        <div className={"flex h-4/5 w-full"}>
                            <StructureDiagram orgId={focusedOrg}/>
                        </div>
                    </div>
                );
            case 'Danger Zone':
                return (
                    <div className="mt-4">
                        <h2 className="text-red-600 font-bold">Danger Zone</h2>
                        <p className="text-gray-700">Here you can perform dangerous actions like deleting the
                            organization.</p>
                        {/* Add any dangerous actions here */}
                        <button
                            onClick={handleSave}
                            className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
                        >
                            Save Settings
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full h-full bg-slate-50 px-6 py-3 flex flex-col">
            <div className="flex space-x-4 border-b mb-4">
                {['Appearance', 'Sites', 'Plans & Billing', 'Danger Zone'].map(tab => (
                    <button
                        key={tab}
                        onClick={() => setActiveTab(tab)}
                        className={`py-1 my-1 px-2 mr-2 transition rounded-lg text-sm font-medium ${activeTab === tab ? 'text-slate-900 bg-slate-200' : 'text-gray-500'}`}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            {renderTabContent()}
        </div>
    );
};

export default OrganizationSettings;
