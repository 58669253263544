import React, {useEffect, useState} from "react";
import LoadingSpinner from "../common/Loading";
import Dropdown from "../common/Dropdown";
import GroupIcon from "../../assets/icons/GroupIcon.svg";
import SortIcon from "../../assets/icons/SortBlack.svg";
import FilterIcon from "../../assets/icons/ConfigureIconBlack.svg";
import ChevronRight from "../../assets/icons/RightChevronBlack.svg"
import {useNavigate} from "react-router-dom";
import { ReactComponent as PlusIcon } from '../../assets/icons/PlusBlack.svg';

const Table = ({
  name = "",
  data = [],
  loading = false,
  columns = [],
  groupByOptions = [],
  sortOptions = [],
  filterOptions = [],
  renderRowActions = () => null,
  renderRow = null, // Custom row rendering
  onGroupBy,
  onSort,
  onFilterBy,
  RenderAdd,
  fetchSiteNames = () => null,
}) => {
  const [groupBy, setGroupBy] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });
  const [filterBy, setFilterBy] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [siteNames, setSiteNames] = useState({});

  useEffect(() => {
    // Fetch site names for grouping
    const fetchSites = async () => {
      if (typeof fetchSiteNames === "function") {
        const sites = await fetchSiteNames();
        setSiteNames(sites); // Map siteId to siteName
      }
    };

    fetchSites();
  }, [fetchSiteNames]);

  const handleToggleDropdown = (name) => {
    setActiveDropdown((prev) => (prev === name ? null : name)); // Close if the same dropdown is clicked
  };

  // Sorting Logic
  const handleGroupBy = (option) => {
    setGroupBy(option.value); // Update state for groupBy
    if (onGroupBy) onGroupBy(option); // Trigger callback
  };

  const handleSort = (option) => {
    const key = option.value;
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction }); // Update state for sorting
    if (onSort) onSort(option); // Trigger callback
  };

  const handleFilterBy = (option) => {
    setFilterBy(option.value); // Update state for filterBy
    if (onFilterBy) onFilterBy(option); // Trigger callback
  };

  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0;
    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === "ascending" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === "ascending" ? 1 : -1;
    return 0;
  });

  const groupedData = groupBy
    ? sortedData.reduce((acc, item) => {
        const key = groupBy === "siteName" && siteNames[item.siteId]
          ? siteNames[item.siteId]
          : item[groupBy] || "Others";
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
      }, {})
    : { "All Devices": sortedData };


  const addAction = () => {
    setAddMenuOpen(true)
  }

  const toggleAddMenu = () => {
    setAddMenuOpen((prev) => !prev); // Toggle the menu state
  };

  if (loading) return (
      <div className={"w-full h-full bg-slate-50"}>
        <div className="flex flex-col justify-between space-y-4 px-6 py-2 h-fit">
          <div className={"flex-grow rounded-lg border h-10 p-2 bg-slate-100 flex"}>
            <div className="flex items-center space-x-0.5">
            </div>
          </div>
          <div className={"flex-grow rounded-lg border h-80 p-2 bg-slate-100 flex"}>
            <div className="flex items-center space-x-0.5">
            </div>
          </div>
        </div>
      </div>
  );

  return (
      <div className="min-h-screen bg-slate-50 font-Poppins">
        {addMenuOpen && <RenderAdd closeMenu={() => setAddMenuOpen(false)}/>}
        <div className="flex justify-between space-x-4 px-6 py-2 h-fit">
          <div className={"flex-grow rounded-lg border h-fit p-2 bg-white flex"}>
            <div className="flex items-center space-x-0.5">
              <div className="py-0.5 px-1 flex transition rounded items-center cursor-default">
                <h1 className="text-sm font-semibold text-slate-500">Devices</h1>
              </div>
              <img src={ChevronRight} className={"h-2 w-2 opacity-30"}/>
              <div className="py-0.5 px-1 flex hover:bg-slate-100 transition rounded cursor-pointer items-center">
                <h1 className="text-sm font-semibold text-slate-700">{name}</h1>
              </div>
            </div>
          </div>
          <div className={"space-x-4 flex w-fit"}>
            <Dropdown
                icon={GroupIcon}
                options={groupByOptions}
                onSelect={handleGroupBy} // Pass the handler directly
                functionName="Group"
                isOpen={activeDropdown === "Group"}
                onToggle={() => handleToggleDropdown("Group")}
            />
            <Dropdown
                icon={SortIcon}
                options={sortOptions}
                onSelect={handleSort} // Pass the handler directly
                functionName="Sort"
                isOpen={activeDropdown === "Sort"}
                onToggle={() => handleToggleDropdown("Sort")}
            />

            <div
                className={"h-full ml-2 rounded-lg flex items-center w-fit text-sm bg-indigo-600 hover:bg-indigo-500 transition cursor-pointer p-2 font-semibold text-white"}
                onClick={() => addAction()}>
            <PlusIcon style={{ stroke: "white" }} className={"h-5 w-5 mr-1"} />
            Add {name}
          </div>
        </div>
      </div>
      {Object.keys(groupedData).map((group, index) => (
        <div className={"rounded-lg border bg-white flex-grow flex flex-col mt-2 mx-6 overflow-clip"}>
            <div key={group} className="">
              <div className={"flex bg-slate-50 px-6 py-2 w-full space-x-1 border-b"}>
              <h3 className="font-semibold text-sm">{group}</h3>
              <div className={"flex items-center"}>
                <h3 className="text-xs font-semibold bg-slate-200 rounded px-1 text-slate-500">{groupedData[group].length}</h3>
              </div>
            </div>
              <table className="w-full border-collapse">
                <thead>
                {!renderRow && (
                    <tr>
                      {columns.map((col) => (
                          <th
                              key={col.key}
                              className="text-left px-4 py-2 border-b text-sm text-slate-500"
                          >
                            {col.title}
                          </th>
                      ))}
                    </tr>
                )}
                </thead>
                <tbody>
                {groupedData[group].map((row) =>
                    renderRow ? (
                        <React.Fragment key={row.id}>{renderRow(row)}</React.Fragment> // Ensure unique keys
                    ) : (
                        <tr key={row.id} className="hover:bg-slate-50 transition">
                          {columns.map((col) => (
                              <td key={col.key} className="px-4 py-2 border-b text-sm text-slate-700">
                                {col.render ? col.render(row[col.key], row) : row[col.key]}
                              </td>
                          ))}
                          <td className="px-4 py-2 border-b">{renderRowActions(row)}</td>
                        </tr>
                    )
                )}
                </tbody>
              </table>
            </div>
        </div>
      ))}
    </div>
  );
};

export default Table;
