import React, { useState, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { fetchSites } from "../../services/firestoreService";
import { IoClose } from "react-icons/io5";
import { ReactComponent as PlusIcon } from "../../assets/icons/PlusBlack.svg";

const SitesList = ({ focusedOrg }) => {
    const [sites, setSites] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [newSite, setNewSite] = useState({ Name: '', Address: '' });

    useEffect(() => {
        const loadSites = async () => {
            if (focusedOrg) {
                try {
                    const siteData = await fetchSites(focusedOrg);
                    setSites(siteData);
                } catch (error) {
                    console.error("Error fetching sites:", error);
                }
            }
        };

        loadSites();
    }, [focusedOrg]);

    const openModal = () => {
        setIsModalVisible(true);
        setTimeout(() => setIsModalActive(true), 10); // Trigger animation
    };

    const closeModal = () => {
        setIsModalActive(false);
        setTimeout(() => setIsModalVisible(false), 300); // Matches animation duration
    };

    const handleAddSite = async () => {
        if (!newSite.Name || !newSite.Address) {
            alert("Please provide both a name and an address for the site.");
            return;
        }

        try {
            const siteRef = collection(db, `organization_data/${focusedOrg}/sites`);
            await addDoc(siteRef, newSite);
            alert("Site added successfully!");

            // Clear the modal and refresh sites
            setNewSite({ Name: '', Address: '' });
            closeModal();

            const siteData = await fetchSites(focusedOrg);
            setSites(siteData);
        } catch (error) {
            console.error("Error adding site:", error);
            alert("Failed to add site.");
        }
    };

    const renderModal = () => (
        <div
            className={`fixed inset-0 -left-4 bg-[#00000050] z-50 flex items-center justify-center transition-opacity duration-300
            ${isModalActive ? "opacity-100" : "opacity-0"}`}
        >
            <div
                className={`bg-white w-96 h-fit rounded-lg overflow-clip shadow-lg flex flex-col transform transition-transform duration-300
                ${isModalActive ? "translate-y-0" : "translate-y-10"}`}
            >
                {/* Modal Header */}
                <div className="h-fit w-full border-b py-4 px-4 flex justify-between items-center">
                    <h1 className="font-semibold text-slate-700">Add New Site</h1>
                    <div
                        className="w-fit h-fit cursor-pointer hover:bg-slate-100 p-1 rounded flex items-center justify-center transition"
                        onClick={closeModal}
                    >
                        <IoClose />
                    </div>
                </div>

                {/* Modal Body */}
                <div className="h-fit w-full px-6 py-6 flex flex-col space-y-4">
                    <input
                        type="text"
                        placeholder="Site Name"
                        value={newSite.Name}
                        onChange={(e) => setNewSite({ ...newSite, Name: e.target.value })}
                        className="p-2 border rounded w-full"
                    />
                    <input
                        type="text"
                        placeholder="Site Address"
                        value={newSite.Address}
                        onChange={(e) => setNewSite({ ...newSite, Address: e.target.value })}
                        className="p-2 border rounded w-full"
                    />
                </div>

                {/* Modal Footer */}
                <div className="h-fit w-full border-t bg-slate-50 p-4 flex justify-end space-x-2 items-center">
                    <button
                        className="w-fit h-fit text-slate-700 text-sm font-medium hover:bg-slate-200 py-2 px-4 rounded-lg transition"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="w-fit h-fit px-4 text-white py-2 text-sm font-medium rounded-lg transition bg-blue-600 hover:bg-blue-500"
                        onClick={handleAddSite}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <div className="space-x-4 flex flex-grow overflow-auto relative">
            {/* Sites List and Add Button */}
            <div className="h-full w-full xl:w-1/2 flex flex-col space-y-2">
                <div
                    className="w-full h-fit p-4 bg-slate-600 shadow-sm cursor-pointer hover:shadow hover:bg-slate-500 transition rounded-lg flex flex-col"
                    onClick={openModal}
                >
                    <div className="font-semibold text-base text-white flex items-center justify-between">
                        Add New Site
                        <PlusIcon style={{ stroke: "white" }} className="h-6 w-6 mr-1" />
                    </div>
                </div>
                {sites.map((site) => (
                    <div
                        key={site.id}
                        className="w-full h-fit cursor-default p-4 bg-white shadow-sm hover:shadow transition rounded-lg flex flex-col"
                    >
                        <div className="font-semibold text-base text-slate-600">{site.Name}</div>
                        <div className="font-medium text-sm text-slate-400">{site.Address}</div>
                    </div>
                ))}
            </div>
            <div className="h-full w-0 hidden xl:flex xl:w-1/2 flex-col space-y-2 bg-slate-100 rounded-lg"></div>
            {/* Modal */}
            {isModalVisible && renderModal()}
        </div>
    );
};

export default SitesList;
