import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

/**
 * Uploads a profile photo to Firebase Storage and returns the download URL.
 *
 * @param {File} file - The profile photo file to upload.
 * @param {string} uid - The user's UID to associate the photo with.
 * @returns {Promise<string>} - The download URL of the uploaded photo.
 */
export const uploadProfilePhoto = async (file, uid) => {
  try {
    // Initialize Firebase Storage
    const storage = getStorage();

    // Create a storage reference for the profile photo
    const storageRef = ref(storage, `profilePhotos/${uid}`);

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);

    console.log("Profile photo uploaded successfully:", snapshot);

    // Get the download URL for the uploaded photo
    const downloadURL = await getDownloadURL(storageRef);

    console.log("Profile photo download URL:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading profile photo:", error);
    throw new Error("Failed to upload profile photo.");
  }
};