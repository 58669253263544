import React, { useState, useRef, useEffect } from "react";

const PalmRegistration = ({ onRegisterPalm, onCancel }) => {
  const [cameraAccess, setCameraAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const videoRef = useRef(null);

  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
        setCameraAccess(true);
      } catch (error) {
        setErrorMessage("Camera access is required to register palms.");
        console.error("Error accessing camera:", error);
      }
    };

    requestCameraAccess();

    return () => {
      // Stop camera stream on component unmount
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const handleCapture = () => {
    if (!videoRef.current) return;

    const canvas = document.createElement("canvas");
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    const palmImage = canvas.toDataURL("image/png"); // Base64-encoded image
    onRegisterPalm(palmImage); // Pass the captured image to the parent component
  };

  if (errorMessage) {
    return <p className="text-red-500">{errorMessage}</p>;
  }

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-lg font-semibold mb-4">Live Camera Feed</h2>
      <div className="w-full max-w-md h-72 bg-gray-200 rounded-lg overflow-hidden mb-4">
        {cameraAccess ? (
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            autoPlay
            playsInline
          />
        ) : (
          <p className="text-center text-gray-500">Requesting camera access...</p>
        )}
      </div>
      <div className="flex justify-between w-full max-w-md">
        <button
          className="py-2 px-4 bg-gray-300 text-black font-semibold rounded-lg"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 bg-primary text-white font-semibold rounded-lg"
          onClick={handleCapture}
          disabled={!cameraAccess}
        >
          Capture Palm
        </button>
      </div>
    </div>
  );
};

export default PalmRegistration;
