import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";

export default function RenderRemove({ closeMenu, recipientName, onConfirm }) {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Delay setting isVisible to true to trigger the transition
    const timer = setTimeout(() => setIsVisible(true), 10);

    return () => {
      clearTimeout(timer); // Cleanup timer when the component unmounts
      setIsVisible(false); // Ensure visibility is reset when unmounting
    };
  }, []);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm(); // Call the provided confirmation function
      setIsVisible(false); // Trigger transition before closing
      setTimeout(() => closeMenu(), 300); // Wait for transition before closing
    } catch (error) {
      console.error("Error removing recipient:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-[#00000050] h-full w-screen z-50 flex items-center justify-center transition-opacity duration-300
      ${isVisible ? "opacity-100" : "opacity-0"}`}
    >
      <div
        className={`bg-white w-96 h-fit rounded-lg shadow-lg flex flex-col transform transition-transform duration-300 overflow-clip
        ${isVisible ? "translate-y-0" : "translate-y-10"}`}
      >
        {/* Header */}
        <div className="h-fit w-full border-b py-4 px-4 flex justify-between items-center">
          <h1 className="font-semibold text-slate-700">Confirm Removal</h1>
          <div
            className="w-fit h-fit cursor-pointer hover:bg-slate-100 p-1 rounded flex items-center justify-center transition"
            onClick={() => {
              setIsVisible(false); // Trigger transition before closing
              setTimeout(closeMenu, 300); // Close after transition completes
            }}
          >
            <IoClose />
          </div>
        </div>

        {/* Body */}
        <div className="h-fit w-full px-6 py-6 flex flex-col space-y-4">
          <p className="text-sm text-slate-600">
            Are you sure you want to remove{" "}
            <span className="font-medium text-slate-800">{recipientName}</span>?
          </p>
        </div>

        {/* Footer */}
        <div className="h-fit w-full border-t bg-slate-50 p-4 flex justify-end space-x-2 items-center">
          <button
            className="w-fit h-fit text-slate-700 text-sm font-medium hover:bg-slate-200 py-2 px-4 rounded-lg transition"
            onClick={() => {
              setIsVisible(false); // Trigger transition before closing
              setTimeout(closeMenu, 300); // Close after transition completes
            }}
          >
            Cancel
          </button>
          <button
            className={`w-fit h-fit px-4 text-white py-2 text-sm font-medium rounded-lg transition ${
              loading ? "bg-gray-400 cursor-not-allowed" : "bg-red-600 hover:bg-red-500"
            }`}
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? "Removing..." : "Remove"}
          </button>
        </div>
      </div>
    </div>
  );
}
