import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MooslixLogoBlack from "../../assets/images/MooslixLogoBlack.svg";
import MooslixLogoTextBlack from "../../assets/images/MooslixLogoTextBlack.svg";
import SignUpHand from "../../assets/images/SignUpHand.png";
import { uploadProfilePhoto } from "../../services/storageService";
import { useAuth } from "../../context/AuthContext";
import { updatePassword } from "firebase/auth";
import PalmRegistration from "../../components/specific/PalmRegistration";

const Onboard = () => {
  const { currentUser } = useAuth();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    orgId: "",
    status: "",
    profilePhoto: null,
  });
  const [palmRegistered, setPalmRegistered] = useState(false); // Placeholder for palm registration
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get("email");
    const orgId = searchParams.get("orgId");
    const status = searchParams.get("status");

    setFormData((prev) => ({
      ...prev,
      email: emailParam || "",
      orgId: orgId || "",
      status: status || "",
    }));
  }, [location]);

  const validateForm = () => {
    const { firstName, lastName, email, password } = formData;
    if (!firstName || !lastName || !email || !password) {
      alert("All fields are required.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }
    if (password.length < 6) {
      alert("Password must be at least 6 characters long.");
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (step === 1 && !validateForm()) return;
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePalmRegistration = () => {
    setPalmRegistered(true);
    alert("Palm registration successful!");
  };

  const handleFileUpload = (file) => {
    if (file && file.type.startsWith("image/")) {
      setFormData((prev) => ({ ...prev, profilePhoto: file }));
      console.log("Uploaded file:", file);
    } else {
      alert("Please upload a valid image file.");
    }
  };


  const handleSubmit = async () => {
  try {
    const { email, password, firstName, lastName, orgId, status } = formData;

    if (!currentUser) {
      throw new Error("No authenticated user found. Please ensure the user is logged in.");
    }

    // Update the user's password
    console.log("Updating password...");
    await updatePassword(currentUser, password);
    console.log("Password updated successfully.");

    // Prepare payload for backend
    const payload = {
      uid: currentUser.uid,
      email: currentUser.email,
      orgId,
      status,
      firstName,
      lastName,
      phoneNumber: formData.phoneNumber || "",
      profileURL: formData.profilePhoto ? await uploadProfilePhoto(formData.profilePhoto, currentUser.uid) : "",
      leftPalmActive: palmRegistered,
      rightPalmActive: palmRegistered,
    };

    console.log("Payload to backend:", payload);

    // Send data to the backend
    const response = await fetch("https://us-central1-mooslix.cloudfunctions.net/addUserToOrg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Failed to update user in backend:", errorData.message);
      alert("Failed to update user in backend: " + errorData.message);
      return;
    }

    const responseData = await response.json();
    console.log("User data updated successfully:", responseData.message);
    alert("Registration completed successfully!");
    navigate("/app/dashboard");
  } catch (error) {
    console.error("Error during registration:", error);
    alert(error.message || "An error occurred during registration.");
  }
};

  return (
    <main className="w-screen h-screen flex justify-center items-center bg-gray-100">
      <section className="w-full h-full flex shadow-lg rounded-lg overflow-hidden bg-white">
        <div className="w-full md:w-3/5 flex flex-col p-8">
          <div className="flex items-center mb-8">
            <a className="flex items-center" href="/">
              <img className="h-10" src={MooslixLogoBlack} alt="Logo" />
              <img className="h-10 ml-2" src={MooslixLogoTextBlack} alt="Mooslix" />
            </a>
          </div>
          <div className="flex flex-col items-center justify-center flex-grow">
            {loading ? (
              <p className="text-lg font-semibold font-Poppins">Processing...</p>
            ) : step === 1 ? (
                <>
                  <h1 className="text-4xl font-semibold mb-4 font-Poppins">Account Setup</h1>
                  <div className={"flex space-x-2 w-full"}>
                    <input
                        className="flex-grow p-4 mb-4 border border-gray-300 rounded-lg font-Poppins"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                    />
                    <input
                        className="flex-grow p-4 mb-4 border border-gray-300 rounded-lg font-Poppins"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                    />
                  </div>
                  <input
                      className="w-full p-4 mb-4 border border-gray-300 rounded-lg font-Poppins"
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Email"
                      required
                      readOnly
                  />
                  <input
                      className="w-full p-4 mb-4 border border-gray-300 rounded-lg font-Poppins"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                      required
                  />
                  <button
                      className="w-full py-4 bg-primary text-white font-semibold rounded-lg font-Poppins"
                      onClick={handleNext}
                  >
                    Continue
                  </button>
                </>
            ) : step === 2 ? (
              <>
                <h1 className="text-4xl font-semibold mb-4 font-Poppins">Final Details</h1>
                <div className={"flex space-x-2 w-full"}>
                  {formData.profilePhoto && (
                  <div className="flex flex-col items-center w-48 ">
                    <img
                      src={URL.createObjectURL(formData.profilePhoto)}
                      alt="Profile Preview"
                      className="w-32 h-32 rounded-xl overflow-clip object-cover mb-4"
                    />
                    <p className="text-sm text-gray-600">{formData.profilePhoto.name}</p>
                  </div>
                )}
                  <div
                      className="w-full h-40 border-2 border-dashed border-gray-300 hover:border-gray-500 transition rounded-lg flex flex-col justify-center items-center p-4 mb-4"
                      onDrop={(e) => {
                        e.preventDefault();
                        const file = e.dataTransfer.files[0];
                        handleFileUpload(file);
                      }}
                      onDragOver={(e) => e.preventDefault()}
                  >
                    <p className="text-gray-500 text-center">
                      Drag and drop your profile photo here or click below to select a file.
                    </p>
                    <button
                        className="py-2 px-4 bg-slate-200 text-slate-500 hover:text-slate-700 transition font-semibold rounded-md mt-2 border-slate-400"
                        onClick={() => document.getElementById("profilePhotoInput").click()}
                    >
                      Select Profile Photo
                    </button>
                    <input
                        className="hidden"
                        type="file"
                        id="profilePhotoInput"
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                    />
                  </div>
                </div>
                <div className={"w-full justify-between flex"}>
                  <button
                      className="py-4 px-8 bg-gray-300 text-black font-semibold rounded-lg"
                      onClick={handleBack}
                  >
                  Back
                  </button>
                  <button
                    className="px-8 py-4 bg-primary text-white font-semibold rounded-lg font-Poppins"
                    onClick={handleNext}
                  >
                    Continue
                  </button>
                </div>
              </>
            ) : (
              <>
                <h1 className="text-4xl font-semibold mb-4">Step 2: Register Your Palms (Optional)</h1>
                <PalmRegistration
                  onRegisterPalm={(palmImage) => {
                    console.log("Captured palm image:", palmImage);
                    alert("Palm registered successfully!");
                    setPalmRegistered(true); // Update parent state if needed
                  }}
                  onCancel={handleBack}
                />
                <div className="flex justify-between w-full mt-4">
                  <button
                    className="py-4 px-8 bg-gray-300 text-black font-semibold rounded-lg"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    className="py-4 px-8 bg-primary text-white font-semibold rounded-lg"
                    onClick={handleSubmit}
                  >
                    Skip Palm Registration
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="hidden md:block md:w-2/5 relative bg-primary text-white p-8 flex flex-col justify-center items-center">
          <img
            className="absolute -bottom-20 inset-x-0 w-full h-full object-cover opacity-30"
            src={SignUpHand}
            alt="Sign Up"
          />
          <div className="relative text-center z-10">
            <h2 className="text-4xl font-medium mb-4">Secure Your Future</h2>
            <p className="text-lg">with Advanced Biometrics</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Onboard;
