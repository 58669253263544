// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_GbZvvnv-YoI9DrP6AqU1NZSi37cKIdk",
  authDomain: "mooslix.firebaseapp.com",
  databaseURL: "https://mooslix-default-rtdb.firebaseio.com",
  projectId: "mooslix",
  storageBucket: "mooslix.appspot.com",
  messagingSenderId: "946627642070",
  appId: "1:946627642070:web:5727777a814bec38cfbf77",
  measurementId: "G-LXKR6E7HJD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)
const functions = getFunctions(app);

// Connect to emulators if running locally
//if (window.location.hostname === "localhost") {
//  connectFirestoreEmulator(db, "localhost", 8080);
// connectFunctionsEmulator(functions, "localhost", 5001);
//  connectAuthEmulator(auth, "http://localhost:9099");
//  connectStorageEmulator(storage, "localhost", 9199);
//}

export { auth, db, storage, functions };
