import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import {fetchOrganizationData, fetchRecipientsByIds} from '../../services/firestoreService';
import SearchContainer from "../layout/SearchComponent";
import { CSSTransition } from 'react-transition-group';

import HomeIcon from '../../assets/icons/DashboardBlack.svg';
import MembersIcon from '../../assets/icons/MembersBlack.svg';
import DevicesIcon from '../../assets/icons/DevicesBlack.svg';
import SettingsIcon from '../../assets/icons/SettingsBlack.svg';
import UpArrow from '../../assets/icons/UpArrowWhite.svg'
import DownChev from '../../assets/icons/DownChevronBlack.svg'
import SignOutIcon from '../../assets/icons/SignOutBlack.svg'
import PlusIcon from '../../assets/icons/PlusBlack.svg'
import DestinationsIcon from '../../assets/icons/RecipientsIcon.svg'
import ViewsIcon from '../../assets/icons/ViewsIcon.svg'
import PersonalSettings from "../specific/PersonalSettings";
import {useOrganization} from "../../context/OrgContext";
import {doc, getDoc, onSnapshot} from "firebase/firestore";
import {db} from "../../firebaseConfig";

export default function LeftNav({ onPageChange }) {
    const {focusedOrg, associatedOrgs} = useOrganization();
    const { logout, currentUser } = useAuth();
    const [activeLink, setActiveLink] = useState(`dashboard`);
    const [orgLogoUrl, setOrgLogoUrl] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [orgName, setOrgName] = useState('');
    const storage = getStorage();
    const [menuOpen, setMenuOpen] = useState(false);
    const [orgMenuOpen, setOrgMenuOpen] = useState(false);
    const [devicesOpen, setDevicesOpen] = useState(true);
    const [analyticsOpen, setAnalyticsOpen] = useState(true);
    const [integrationsOpen, setIntegrationsOpen] = useState(true);
    const [isPersonalSettingsOpen, setIsPersonalSettingsOpen] = useState(false);
    const [orgData, setOrgData] = useState(null)
    const [recipients, setRecipients] = useState([]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        setOrgMenuOpen(false);
    };
    const toggleOrgMenu = () => {
        setMenuOpen(false);
        setOrgMenuOpen(!orgMenuOpen);
    };

    const toggleDevices = () => {
        setDevicesOpen(!devicesOpen);
    };
    const toggleAnalytics = () => {
        setAnalyticsOpen(!analyticsOpen);
    };

    const toggleIntegrations = () => {
        setIntegrationsOpen(!integrationsOpen)
    }

    useEffect(() => {
      if (!focusedOrg) {
        console.warn("No focused organization.");
        return;
      }

      // Listener for real-time updates
      const orgRef = doc(db, "organization_data", focusedOrg);

      const fetchImages = async () => {
            if (!focusedOrg || !currentUser) {
                console.warn("Focused organization or current user is not set.");
                return;
            }

            try {
                // Fetch Profile Picture
                if (currentUser.ProfileURL) {
                    const profilePicRef = ref(storage, currentUser.ProfileURL);
                    const profilePicUrl = await getDownloadURL(profilePicRef);
                    setProfilePicUrl(profilePicUrl);
                } else {
                    console.warn("No profile picture URL found for the current user.");
                }

                // Fetch Organization Data
                const orgData = await fetchOrganizationData(focusedOrg);

                if (orgData) {
                    setOrgName(orgData.Name || "No Name");
                    setOrgData(orgData);

                    if (orgData.Logo) {
                        const orgLogoRef = ref(storage, orgData.Logo);
                        const orgLogoUrl = await getDownloadURL(orgLogoRef);
                        setOrgLogoUrl(orgLogoUrl);
                    } else {
                        console.warn("No logo found for the organization.");
                    }
                } else {
                    console.error("Organization data not found for the focused organization.");
                }
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };

      fetchImages();

      const unsubscribe = onSnapshot(
        orgRef,
        async (orgDoc) => {
          if (orgDoc.exists()) {
            const recipientIds = orgDoc.data().Recipients || [];
            console.log("Real-time update for recipients:", recipientIds);

            if (recipientIds.length > 0) {
              try {
                const recipientList = await fetchRecipientsByIds(recipientIds);

                // Fetch logos for recipients
                const logoPromises = recipientList.map(async (recipient) => {
                  if (recipient.Logo) {
                    try {
                      const logoRef = ref(storage, `recipient_logos/${recipient.Logo}`);
                      const logoUrl = await getDownloadURL(logoRef);
                      return { ...recipient, logoUrl };
                    } catch (error) {
                      console.error(`Error fetching logo for recipient ${recipient.id}:`, error);
                      return { ...recipient, logoUrl: null };
                    }
                  }
                  return { ...recipient, logoUrl: null };
                });

                const recipientsWithLogos = await Promise.all(logoPromises);
                setRecipients(recipientsWithLogos);
              } catch (error) {
                console.error("Error fetching recipients by IDs:", error);
              }
            } else {
              console.warn("No recipients found in organization.");
              setRecipients([]);
            }
          } else {
            console.error("Organization document not found.");
          }
        },
        (error) => {
          console.error("Error listening for real-time updates:", error);
        }
      );

      // Cleanup listener when component unmounts or focusedOrg changes
      return () => unsubscribe();
    }, [focusedOrg, storage]);

    const handleLinkClick = (link) => {
        setActiveLink(link);
        onPageChange(link);
    };

    const linkClasses = (link) => {
        return `w-full h-8 text-sm font-Poppins transition rounded flex space-x-1 items-center font-medium cursor-pointer pl-2 ${
            activeLink === link ? 'bg-slate-100 text-slate-700' : 'hover:text-slate-700 text-slate-500'
        }`;
    };

    return (
        <div className="flex flex-col w-56 h-full border-r-slate-100 border-r space-y-4 px-2 py-2 relative font-Poppins">
            <div className="w-full h-fit flex justify-between">
                <PersonalSettings isOpen={isPersonalSettingsOpen} onClose={() => setIsPersonalSettingsOpen(false)}/>
                <div
                    className={`w-fit h-7 flex items-center justify-start space-x-1 px-2 my-1.5 hover:bg-slate-100 ${orgMenuOpen ? "rounded-t-lg bg-slate-100" : "rounded-lg bg-white"}  relative cursor-pointer transition`}
                    onClick={toggleOrgMenu}>
                    {orgLogoUrl ? (
                        <img src={orgLogoUrl} alt="Org Logo" className="rounded-md h-5 w-5"/>
                    ) : (
                        <div className="bg-slate-200 rounded-md h-5 w-5"></div>
                    )}
                    <div
                        className="font-semibold text-slate-900 text-sm w-28 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {orgName || "Loading..."}
                    </div>
                    <img className={`h-5 w-5 opacity-50 scale-75 transition ${orgMenuOpen ? "-rotate-180" : ""}`} src={DownChev}/>
                    {orgMenuOpen && (
                        <div
                            className="absolute top-7 -left-1 w-full bg-slate-100 shadow-xl rounded-b-lg z-10 overflow-clip">
                            <button
                                className="group flex items-center space-x-1 w-full text-left px-2 py-1 text-gray-700 hover:bg-slate-200 transition">
                                <img src={PlusIcon} className={"h-5 w-5 opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>New
                                    Organization</h1>
                            </button>
                        </div>
                    )}
                </div>
                <div className="relative flex-grow h-10 flex justify-end items-center mr-1 space-x-1">
                    {profilePicUrl ? (
                        <button onClick={toggleMenu}>
                            <img src={profilePicUrl} alt="Profile Pic"
                                 className="rounded-full h-8 w-8 p-1 hover:bg-slate-100 transition"/>
                        </button>
                    ) : (
                        <button onClick={toggleMenu}>
                            <div className="bg-slate-200 rounded-full h-8 w-8"></div>
                        </button>
                    )}
                    {menuOpen && (
                        <div
                            className="absolute top-10 -right-1 w-52 bg-white shadow-xl border rounded-lg z-10 overflow-clip">
                            <button onClick={() => setIsPersonalSettingsOpen(true)}
                                    className="group flex items-center space-x-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-slate-50 transition">
                                <img src={SettingsIcon} className={"opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>Settings</h1>
                            </button>
                            <button onClick={logout}
                                    className="group flex items-center space-x-2 w-full text-left px-4 py-2 text-gray-700 hover:bg-slate-50 transition">
                                <img src={SignOutIcon} className={"opacity-30 group-hover:opacity-75 transition"}/>
                                <h1 className={"text-sm font-medium text-slate-400 group-hover:text-slate-600 transition"}>Log
                                    Out</h1>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <SearchContainer/>
            <div className="w-full h-fit flex flex-col">
                <div className={linkClasses('dashboard')} onClick={() => handleLinkClick('dashboard')}>
                    <img className="h-5 w-5 opacity-70" src={HomeIcon} alt="Home"/>
                    <h1>Home</h1>
                </div>
                <div className={linkClasses('team')} onClick={() => handleLinkClick('team')}>
                    <img className="h-5 w-5 opacity-70" src={MembersIcon} alt="Team"/>
                    <h1>Team</h1>
                </div>
            </div>
            {/* Security Section with Collapsible Content */}
            <div className="w-full h-fit flex flex-col">
                <div
                    className="font-medium text-slate-500 text-sm cursor-pointer pl-2 space-x-1 flex items-center justify-between">
                    <div className={"flex flex-row space-x-1 items-center"} onClick={toggleDevices}>
                        <img className="h-5 w-5 opacity-70" src={DevicesIcon} alt="Locks"/>
                        <h1 className={""}>Devices</h1>
                        <img src={DownChev}
                             className={`${devicesOpen ? "" : "-rotate-90"} opacity-30 inline-block h-3.5 w-3.5 transition`}/>
                    </div>
                </div>
                <CSSTransition
                    in={devicesOpen}
                    timeout={100}
                    classNames="dropdown"
                    unmountOnExit
                >
                    <div className={"w-full h-fit flex space-x-1 pl-3"}>
                        <div className={"h-full w-0.5 pt-1"}>
                            <div className={"h-full w-full bg-slate-100 rounded-full"}></div>
                        </div>
                        <ul className="flex-grow h-fit mt-1">
                            {orgData?.Devices ? (
                                <>
                                    <li
                                        className={`${linkClasses('locks')} ${
                                            orgData.Devices['Locks'] === true ? "visible" : "hidden"
                                        }`}
                                        onClick={() => handleLinkClick('locks')}
                                    >
                                        <h1>Locks</h1>
                                    </li>
                                    <li
                                        className={`${linkClasses('registers')} ${
                                            orgData.Devices['Registers'] === true ? "visible" : "hidden"
                                        }`}
                                        onClick={() => handleLinkClick('registers')}
                                    >
                                        <h1>Registers</h1>
                                    </li>
                                    <li
                                        className={`${linkClasses('checkpoints')} ${
                                            orgData.Devices['Checkpoints'] === true ? "visible" : "hidden"
                                        }`}
                                        onClick={() => handleLinkClick('checkpoints')}
                                    >
                                        <h1>Checkpoints</h1>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <div className="bg-slate-100 rounded h-7 flex-grow mb-1"></div>
                                    <div className="bg-slate-100 rounded h-7 flex-grow mb-1"></div>
                                    <div className="bg-slate-100 rounded h-7 flex-grow"></div>
                                </>
                            )}
                        </ul>
                    </div>
                </CSSTransition>
            </div>
            {/* Security Section with Collapsible Content */}
            <div className="w-full h-fit flex flex-col">
                <div className="font-medium text-slate-500 text-sm cursor-pointer pl-2 space-x-1 flex items-center"
                     onClick={toggleAnalytics}>
                    <img className="h-5 w-5 opacity-70" src={ViewsIcon} alt="Locks"/>
                    <h1 className={""}>Views</h1>
                    <img src={DownChev}
                         className={`${analyticsOpen ? "" : "-rotate-90"} opacity-30 inline-block h-3.5 w-3.5 transition`}/>
                </div>
                <CSSTransition
                    in={analyticsOpen}
                    timeout={100}
                    classNames="dropdown"
                    unmountOnExit
                >
                    <div className={"w-full h-fit flex space-x-1 pl-3"}>
                        <div className={"h-full w-0.5 pt-1"}>
                            <div className={"h-full w-full bg-slate-100 rounded-full"}></div>
                        </div>
                        <ul className={"flex-grow h-fit mt-1"}>
                            <li className={linkClasses('map')} onClick={() => handleLinkClick('map')}>
                                <h1>Map</h1>
                            </li>
                        </ul>
                    </div>
                </CSSTransition>
            </div>
            <div className={"w-full h-fit flex flex-col"}>
                <div
                    className="font-medium text-slate-500 text-sm cursor-default pl-2 flex items-center justify-between">
                    <div className={"flex flex-row space-x-1 items-center cursor-pointer"}
                         onClick={toggleIntegrations}
                    >
                        <img className="h-5 w-5 opacity-70" src={DestinationsIcon} alt="Locks"/>
                        <h1 className={""}>Recipients</h1>
                        <img src={DownChev}
                             className={`${integrationsOpen ? "" : "-rotate-90"} opacity-30 inline-block h-3.5 w-3.5 transition`}/>
                    </div>
                    <div className={"hover:bg-slate-100 group transition h-full aspect-square flex items-center justify-center cursor-pointer rounded"} onClick={() => handleLinkClick("recipients/new")}>
                        <img src={PlusIcon} className={`opacity-30 group-hover:opacity-75 inline-block h-3.5 w-3.5 transition`}/>
                    </div>
                </div>
                <CSSTransition
                    in={integrationsOpen}
                    timeout={100}
                    classNames="dropdown"
                    unmountOnExit
                >
                    <div className={"w-full h-fit flex space-x-1 pl-3"}>
                        <div className={"h-full w-0.5 pt-1"}>
                            <div className={"h-full w-full bg-slate-100 rounded-full"}></div>
                        </div>
                        <ul className="flex-grow h-fit mt-1">
                            {recipients.length > 0 ? (
                                recipients.map((recipient) => (
                                    <li
                                        key={recipient.id}
                                        className={linkClasses(`recipients/${recipient.id}`)}
                                        onClick={() => handleLinkClick(`recipients/${recipient.id}`)}
                                    >
                                        <div className="flex items-center space-x-1.5">
                                            {recipient.logoUrl ? (
                                                <img
                                                    src={recipient.logoUrl}
                                                    alt={`${recipient.Name || "Recipient"} Logo`}
                                                    className="h-5 w-5 rounded-full"
                                                />
                                            ) : (
                                                <div
                                                    className="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center text-gray-500">
                                                    No Logo
                                                </div>
                                            )}
                                            <h1 className={"overflow-ellipsis"}>{recipient.Name || "Unnamed Recipient"}</h1>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <div className="bg-slate-100 rounded h-7 flex-grow"></div>
                            )}
                        </ul>
                    </div>
                </CSSTransition>
            </div>
            <div className="flex-grow w-full"></div>
            <div className="w-full h-fit flex flex-col">
                <ul>
                    <li className={linkClasses('settings')} onClick={() => handleLinkClick('settings')}>
                        <img className="h-5 w-5 opacity-70" src={SettingsIcon} alt="settings"/>
                        <h1>Settings</h1>
                    </li>
                </ul>
            </div>
            <div className={"px-1"}>
                <div
                    className={"border rounded-full px-1 text-xs py-1 font-medium flex items-center space-x-1 hover:border-slate-500 transition cursor-pointer"}>
                    <img className="h-5 w-5 bg-black rounded-full opacity-70 mr-0.5" src={UpArrow} alt="Support"/>
                    <h1>Upgrade to Mooslix Max</h1>
                </div>
            </div>
        </div>
    );
}
